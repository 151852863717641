












import { Core } from "@/store/core";

import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
    components: {

    },
    computed: {}
})

export default class UserClass extends Vue {
    model: any = {
        id: 1,
        name: "John Doe",
        password: "J0hnD03!x4",
        age: 35,
        skills: ["Javascript", "VueJS"],
        email: "john.doe@gmail.com",
        status: true
    }
    schema: any = {
        fields: [{
            type: "input",
            inputType: "text",
            label: "ID",
            model: "id",
            readonly: true,
            featured: false,
            disabled: true
        }, {
            type: "input",
            inputType: "text",
            label: "Name",
            model: "name",
            readonly: false,
            featured: true,
            required: true,
            disabled: false,
            placeholder: "User's name",
        }, {
            type: "input",
            inputType: "password",
            label: "Password",
            model: "password",
            min: 6,
            required: true,
            hint: "Minimum 6 characters",

        }, {
            type: "input",
            inputType: "number",
            label: "Age",
            model: "age",
            min: 18,

        }, {
            type: "input",
            inputType: "email",
            label: "E-mail",
            model: "email",
            placeholder: "User's e-mail address",

        }, {
            type: "checklist",
            label: "Skills",
            model: "skills",
            multi: true,
            required: true,
            multiSelect: true,
            values: ["HTML5", "Javascript", "CSS3", "CoffeeScript", "AngularJS", "ReactJS", "VueJS"]
        }, {
            type: "switch",
            label: "Status",
            model: "status",
            multi: true,
            readonly: false,
            featured: false,
            disabled: false,
            default: true,
            textOn: "Active",
            textOff: "Inactive"
        }]
    }

    formOptions: any = {
        validateAfterLoad: true,
        validateAfterChanged: true
    }
    form: any = {
        "schema": {
            "fields": [{
                    "label": "ประเภทตัวเลือก",
                    "hint": "",
                    "model": "name",
                    "default": null,
                    "required": true,
                    "inputType": "text",
                    "type":"input"
                },
                {
                    "label": "ชื่อตัวเลือก",
                    "hint": "",
                    "model": "value",
                    "default": null,
                    "required": true,
                    "inputType": "text",
                    "type":"input"
                }
            ]
        },
        "model": {
            "name": null,
            "value": null
        }
    }
    response: boolean = false
    async created() {
    //    this.form = await Core.getHttp(`/api/user/test2`)
        this.response = true
    }

}
